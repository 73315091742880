(function() {
  'use strict';

  var ANIMATION_END = (function () {
    var a,
        el = document.createElement("fakeelement");

    var animations = {
      'animation'      : 'animationend',
      'OAnimation'     : 'oAnimationEnd',
      'MozAnimation'   : 'animationend',
      'WebkitAnimation': 'webkitAnimationEnd'
    };

    for (a in animations){
      if (el.style[a] !== undefined){
        return animations[a];
      }
    }
  })();

  var PageVisibility = {
    hidden: false,
    _onVisible: function() {
      App.Manifesto.run();
    },
    _onHidden: function() {
      App.Manifesto.clearInterval();
    },
    _visibilityChangeHandler: function(e) {
      if (e.type === 'visibilitychange') {

        if (document.hidden) {
          PageVisibility.hidden = true;
          PageVisibility._onHidden();
        } else {
          PageVisibility.hidden = false;
          PageVisibility._onVisible();
        }
        return;
      }

      if (e.type === 'focus') {
        PageVisibility.hidden = false;
        PageVisibility._onVisible();
        return;
      }

      if (e.type === 'blur') {
        PageVisibility.hidden = true;
        PageVisibility._onHidden();
        return;
      }
    },
    init: function() {
      document.addEventListener('visibilitychange', PageVisibility._visibilityChangeHandler);
      window.addEventListener('focus', PageVisibility._visibilityChangeHandler);
      window.addEventListener('blur', PageVisibility._visibilityChangeHandler);
    }
  };

  var PageTransition = {
    init: function() {
      // window.addEventListener('pagehide', PageTransition._pagehideHandler);
      window.addEventListener('pageshow', PageTransition._pageshowHandler);
    },
    // _pagehideHandler: function(e) {},
    _pageshowHandler: function(e) {
      var loading;
      // remove loading layer, if exists
      if ((loading = document.getElementById('js-loading'))) {
        loading.remove();
      }
    }
  };

  var Nav = {
    $container: document.getElementById('js-nav-container'),
    $el: document.querySelector('.js-nav-primary'),
    $toggle: document.querySelector('.js-nav-toggle'),
    $links: document.querySelectorAll('.js-nav-primary .nav__link'),
    isOpen: false,
    labels: null,
    init: function() {
      if (Nav.$toggle) {
        Nav.$toggle.addEventListener('click', Nav.toggle);
      }
      Array.prototype.forEach.call(Nav.$links, function(link) {
        // Nav._setMouseOverWidth(link);
        if (App.Util.isNotMobile()) {
          link.addEventListener('mouseover',  Nav._onMouseOver);
          link.addEventListener('mouseout',   Nav._onMouseOut);
        }
      });
      this._setLabels();
    },
    toggle: function() {
      if (Nav.isOpen) {
        Nav.close();
      } else {
        Nav.open();
      }
    },
    open: function() {
      var root = document.getElementsByTagName('html')[0];

      Nav.isOpen = true;
      Nav.$container.addEventListener(ANIMATION_END, Nav.afterOpen);

      root.classList.add('no-scroll');
      Nav.$container.classList.remove('is-hidden');
      Nav.$container.classList.add('fadeIn', 'animated');
      Nav.$el.classList.add('fadeInLeft', 'animated');

      Nav.$toggle.innerHTML = `<span>${this.labels.close}</span>`;
      Nav.$toggle.style.zIndex = 100;
    },
    afterOpen: function() {
      Nav.$container.removeEventListener(ANIMATION_END, Nav.afterOpen);
    },
    close: function() {

      Nav.isOpen = false;
      Nav.$container.addEventListener(ANIMATION_END, Nav.afterClose);

      Nav.$container.classList.add('fadeOut');
      Nav.$el.classList.add('fadeOutLeft');

      Nav.$toggle.innerHTML = `<span>${this.labels.open}</span>`;
      Nav.$toggle.style.zIndex = '';
    },
    afterClose: function() {
      var root = document.getElementsByTagName('html')[0];

      Nav.$container.classList.add('is-hidden');

      Nav.$container.classList.remove('fadeIn', 'fadeOut', 'animated');
      Nav.$el.classList.remove('fadeInLeft', 'fadeOutLeft', 'animated');
      root.classList.remove('no-scroll');

      Nav.$container.removeEventListener(ANIMATION_END, Nav.afterClose);
    },
    _onMouseOver: function(e) {
      var text = e.target.innerText;
      var mouseover = e.target.getAttribute('data-mouseover');
      var $newTag = e.target.parentNode.querySelector('.new-project-tag');
      if (mouseover) {
        // force width if mouseover text is shorter than link text
        if (mouseover.length < text.length) {
          e.target.style.width = e.target.offsetWidth + 'px';
        }
        e.target.innerText = mouseover;
        e.target.setAttribute('data-link-text', text);
        if ($newTag) {
          $newTag.classList.add('visuallyhidden');
        }
      }
    },
    _onMouseOut: function(e) {
      var text = e.target.getAttribute('data-link-text');
      var mouseover = e.target.getAttribute('data-mouseover');
      var $newTag = e.target.parentNode.querySelector('.new-project-tag');
      if (text) {
        e.target.style.width = null;
        e.target.innerText = text;
      }
      if ($newTag) {
        $newTag.classList.remove('visuallyhidden');
      }
    },
    _setMouseOverWidth: function(link) {
      var mouseOver = link.querySelector('.nav__mouseover');
      var navTitle = link.querySelector('.nav__title');
      if ( !mouseOver ) {
        return;
      }

      if (mouseOver.offsetWidth < link.offsetWidth) {
        mouseOver.style.width = link.offsetWidth + 'px';
      } else {
        navTitle.style.width = mouseOver.offsetWidth;
      }
    },
    _setLabels: function() {
      if (window.App.isArchive) {
        this.labels = {
          open: 'Open Archive',
          close: 'Close Archive'
        };
      } else {
        this.labels = {
          open: 'Open Menu',
          close: 'Close Menu'
        };
      }
    }
  };

  window.LinkManager = {
    listen: function() {
      var links = document.querySelectorAll('a');

      links = Array.from(links).filter(function(link) {
        if (link.getAttribute('target')) {
          return false;
        }
        
        let href = link.getAttribute('href');
        // Filter out tel and mailto links
        if (href.indexOf('tel:') > -1 || href.indexOf('mailto:') > -1) {
          return false;
        }

       return true;
      });

      Array.prototype.forEach.call(links, function(link) {
        link.addEventListener('click', LinkManager.onClick);
      });
    },
    onClick: function(e) {
      var target = e.target;
      var baseHref = document.getElementsByTagName('base')[0].getAttribute('href');

      // find first anchor parent
      if (target.href === undefined) {
        do {
          target = target.parentNode;
        } while (target.nodeName !== 'A');
      }


      // only animate internal pages and those w/o target="_blank"
      if (target.href.indexOf(baseHref) >= 0 || target.getAttribute('target') !== '_blank') {

        e.preventDefault();

        // setup loading div and redirect to page after animation
        var loading = document.createElement('div');
        loading.setAttribute('id', 'js-loading');
        loading.addEventListener(ANIMATION_END, function(e) {
          window.location = target.href;
        });
        document.body.appendChild(loading);
        loading.setAttribute('class', 'loading fadeIn animated');
      }
    }
  };

  Nav.init();
  LinkManager.listen();
  PageVisibility.init();
  PageTransition.init();

  // instantiate swipers
  Array.prototype.forEach.call(document.querySelectorAll('.rte .swiper-container'), function(elem) {
    if(elem.classList.contains('swiper--autoplay')) {
      new Swiper(elem, {
        autoplay: 1500,
        effect: 'fade',
        speed: 1,
        loop: true,
        onTap: function(swiper, event) {
          swiper.slideNext();
        }
      });
    }
    else {
      new Swiper(elem, {
        effect: 'fade',
        speed: 1,
        loop: true,
        onTap: function(swiper, event) {
          swiper.slideNext();
        },
        pagination: '.swiper-pagination',
        paginationType: 'custom',
        paginationCustomRender: function (swiper, current, total) {
          return current + ' / ' + total;
        }
      });
    }
  });

})();
